.navbar-contact {
  justify-content: flex-end;
  @media screen and (max-width: 991px) {
    display: none;
  }
  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    background: #570b74;
    height: 45px;
    align-items: center;
    border-radius: 0 0 12px 12px;
    margin-right: 30px;
    @media screen and (min-width: 1366px) {
      max-width: 45%;
    }
    a {
      color: white;
      font-size: 9px;
      font-family: $GothamRnd-Light;
      text-decoration: none !important;
    }
  }
  .message {
    &.call {
      img {
        height: 12px;
      }
    }
    &.social-networks {
      margin-left: 13px;
      img {
        height: 13px;
      }
      a {
        margin-right: 5px;
      }
    }
    img {
      height: 9px;
    }
  }
}
