@font-face {
  font-family: GothamRnd-Bold;
  src: url('./../assets/fonts/GothamRnd-Bold.otf');
}
@font-face {
  font-family: GothamRnd-BoldIta;
  src: url('./../assets/fonts/GothamRnd-BoldIta.otf');
}
@font-face {
  font-family: GothamRnd-Book;
  src: url('./../assets/fonts/GothamRnd-Book.otf');
}
@font-face {
  font-family: GothamRnd-BookIta;
  src: url('./../assets/fonts/GothamRnd-BookIta.otf');
}
@font-face {
  font-family: GothamRnd-Light;
  src: url('./../assets/fonts/GothamRnd-Light.otf');
}
@font-face {
  font-family: GothamRnd-Lightlta;
  src: url('./../assets/fonts/GothamRnd-LightIta.otf');
}
@font-face {
  font-family: GothamRnd-MedIta;
  src: url('./../assets/fonts/GothamRnd-MedIta.otf');
}
@font-face {
  font-family: GothamRnd-Medium;
  src: url('./../assets/fonts/GothamRnd-Medium.otf');
}
