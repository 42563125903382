.footer-copyright {
  display: flex;
  justify-content: space-evenly;
  background-color: $purple;
  color: #faf9fa;
  font-size: 13px;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    font-size: 13px;
    justify-content: space-between;
  }
  & img {
    width: 100px;
    margin-left: 8px;
  }
  > div {
    margin-left: 45px;
    display: flex;
    align-items: center;
    span {
      font-size: 15px;
      font-family: GothamRnd-Light;
      margin-top: 4px;
    }
  }
}

.footer-details {
  display: flex;
  justify-content: flex-end;
  background-color: $celeste2;
  padding: 70px 0 68px;
  & img {
    width: 14px;
    margin-right: 5px;
  }
  & a {
    color: $white;
    font-family: GothamRnd-Light;
    font-size: 16px;
  }
  & .col-12 {
    @media screen and (max-width: 480px) {
      margin-top: 45px;
      padding: 0px 0 0 40px !important;
    }
  }
}
.networks {
  margin-top: 45px;
  @media screen and (max-width: 480px) {
    margin-top: 10px;
  }
  img {
    height: 25px;
    width: 25px;
  }
  .fa-circle {
    color: $white;
  }
  .fa-inverse {
    color: $celeste2;
  }
}
.text-uppercase {
  margin-bottom: 20px;
}
.footer-academias {
  @media screen and (max-width: 414px) {
    margin-top: -65px;
  }
}
