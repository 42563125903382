///////////////////////INDEX4///////////////////////

.send {
  width: 25%;
  border-radius: 7px !important;
  background-color: #fd4409 !important;
  font-family: "GothamRnd-Book";
  font-size: 10px !important;
  padding: 7px !important;
  margin-bottom: 100px !important;
  outline: none !important;
  @media screen and (max-width: 480px) {
    width: 45%;
    margin-bottom: 0px !important;
  }
  @media screen and (max-width: 768px) {
    width: 45%;
    margin-bottom: 0px !important;
  }
  @media screen and (max-width: 1024px) {
    width: 45%;
    margin-bottom: 0px !important;
  }
}
.btn {
  &--request {
    color: $white;
    background: $celeste;
    border-radius: 8px;
    font-size: 10px;
    border: none;
    padding: 8px;
    margin-top: 10px;
    width: 40%;
    outline: none !important;
  }
  &--more {
    color: #fff;
    background: #fd4409;
    border-radius: 8px;
    font-size: 12px;
    border: none;
    padding: 8px;
    margin-top: 10px;
    min-width: 24%;
    outline: none !important;
    @media screen and (max-width: 480px) {
      padding: 7px;
    }
  }
  &--details {
    color: $white;
    background: $celeste;
    border-radius: 8px;
    font-size: 10px;
    border: none;
    padding: 8px;
    margin-top: 10px;
    min-width: 40%;
    outline: none !important;
    margin-bottom: 100px;
  }
  &--big {
    font-size: 20px;
    background: $celeste;
    font-family: $GothamRnd-Medium;
    border-radius: 10px;
    color: $white;
    outline: none !important;
    border: none;
    padding: 14px 40px;
    @media screen and (max-width: 1024px) {
      margin-left: 50px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    @media screen and (max-width: 640px) {
      padding: 14px 25px;
    }
  }
}
.btn-link {
  text-decoration: none !important;
  width: 30%;
  margin: 0 10px 10px 0px;
  font-family: $GothamRnd-Medium;
  font-size: 12px;
  background-color: $celeste2;
}
.btn-send {
  margin-right: 37px;
  text-align: end;
  & button {
    background-color: $purple;
    color: $white;
    padding: 10px 30px;
    border-radius: 10px;
    font-size: 14px;
    border: none !important;
    outline: none !important;
  }
}
.btn-modality {
  background-color: $celeste2;
  text-align: start;
  border-radius: 8px;
  padding: 10px;
  font-family: $GothamRnd-Book;
  font-size: 10px;
  color: $white;
  border: none;
}
.btn-marcos {
  background-color: $celeste2;
  text-align: center;
  border-radius: 8px;
  padding: 10px;
  font-family: $GothamRnd-Book;
  font-size: 10px;
  color: $white;
  border: none;
  width: 65%;
}
.btn-escolar {
  background-color: $celeste2;
  text-align: center;
  border-radius: 8px;
  padding: 10px;
  font-family: $GothamRnd-Book;
  font-size: 10px;
  color: $white;
  border: none;
  width: 80%;
}
