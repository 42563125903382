.navbar {
  .navbar-brand {
    img {
      width: 70%;
    }
  }
  .nav-link {
    font-size: 13px;
    color: $purple;
    font-family: $GothamRnd-Medium;
    @media screen and (max-width: 1024px) {
      font-size: 11px;
    }
  }
  .navbar-toggler-icon {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}
