.dropdown-item {
  padding: 0 15px;
  background-color: transparent !important;
  a {
    &:first-of-type {
      color: #5c0c7b !important;
    }
    color: #fd4409 !important;
  }
}
