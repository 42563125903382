.select-font {
  background: $celeste !important;
  color: white !important;
  font-size: 10px !important;
  height: 33px !important;
  width: 70% !important;
  border-radius: 9px !important;
  font-family: $GothamRnd-Light !important;
  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}
.select-font-option select {
  background: $celeste5;
  color: #45c8e3;
  font-size: 14px;
  font-family: $GothamRnd-Light;
  border: none;
  margin-left: 8px;
  width: 85%;
}

.purple {
  font-size: 14px;
  color: $purple2;
  font-family: $GothamRnd-Medium;
}

.text {
  &--white2 {
    color: white;
    font-family: "GothamRnd-Medium";
    font-size: 40px;
    margin-bottom: 30px;
    @media screen and (max-width: 480px) {
      font-size: 29px;
    }
  }
}

.full {
  &--width {
    max-width: 100% !important;
  }
}
hr {
  background-color: #cceeff !important;
  border: 1 !important;
}
strong {
  font-family: $GothamRnd-Medium;
}
.contact-form {
  font-size: 9px !important;
  font-family: $GothamRnd-Light;
  border-radius: 10px;
  padding-left: 13px !important;
  border: 1px solid #999 !important;
  height: 35px;
  &textarea {
    padding-top: 20px;
  }
}
.number {
  align-items: center;
  margin: 5px 0;
  @media screen and (max-width: 1024px) {
    margin: 0;
    margin-top: 4px;
  }
  & p {
    margin: 0;
    margin-left: 9px;
  }
  & img {
    height: 23px;
  }
}
.address {
  align-items: center;
  margin: 5px 0;
  @media screen and (max-width: 1024px) {
    margin: 0;
    margin-top: -16px;
  }
  & p {
    margin: 0;
    margin-left: 9px;
  }
  & img {
    height: 23px;
  }
}
.contact-padding {
  @media screen and (max-width: 768px) {
    padding: 0px 0 0 40px !important;
  }
}

.pb-7 {
  padding-bottom: 70px;
}
.group-text {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  justify-content: center;
  line-height: 1;
  @media screen and (max-width: 480px) {
    margin-left: 20px;
  }
}
.category-uni,
.category-marcos,
.category-escolar {
  margin-bottom: 5em;
  @media screen and (max-width: 731px) {
    margin-left: 10%;
  }
  @media screen and (max-width: 900px) {
    margin-left: 0%;
  }
}
