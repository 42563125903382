.paragraph {
  color: #7f8081;
}

.paragraph {
  &--white {
    font-size: 14px;
    font-family: $GothamRnd-Light;
    color: $white;
    margin-top: 10px;
  }
  &--lead {
    font-size: 14px;
    font-family: $GothamRnd-Light;
    color: $lead;
    margin-top: 10px;
    @media screen and (max-width: 480px) {
    }
  }
}
