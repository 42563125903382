#calendar {
  td.fc-day {
    border: 1px solid #a5a5a5;
    > div {
      min-height: 127px;
    }
  }
  .fc-event {
    padding: 10px;
    color: $celeste;
    font-size: 14px;
    font-family: $GothamRnd-Light;
  }

  button.fc-text-arrow {
    border: none;
    outline: none;
    background-color: transparent;
    &[disabled] {
      cursor: not-allowed;
    }
  }

  .fc-day-number {
    margin: 7px;
    color: #a5a5a5;
  }
  .fc-state-highlight > div > div.fc-day-number {
    background-color: #ff3b30;
    color: #ffffff;
    border-radius: 50%;
  }

  tr.fc-first.fc-last {
    background-color: #828282;
    color: white;
    height: 33px;
    font-size: 12px;
    text-align: center;
  }

  table.fc-border-separate {
    margin: 10px 0 50px 0;
  }
  span.fc-button {
    cursor: pointer;
    color: #a5a5a5;
  }

  td.fc-header-center {
    text-align: center;
    color: $celeste;
  }
  td.fc-header-right {
    text-align: right;
  }
}
#calendar .fc-week .fc-day > div .fc-day-number {
  font-size: 15px;
  min-width: 19px;
  padding: 4px;
  text-align: center;
  width: 30px;
  height: 30px;
}
#calendar .fc-grid .fc-other-month .fc-day-number {
  opacity: 0.3;
  filter: alpha(opacity=30);
}
