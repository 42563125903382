.sub-title {
  color: $celeste4;
  font-size: 20px;
  font-family: $GothamRnd-Bold;
  &--small {
    color: $lead3;
    font-size: 15px;
    font-family: $GothamRnd-Medium;
  }
}

.title {
  &--normal {
    color: $celeste4;
    font-size: 30px;
    font-family: $GothamRnd-Bold;
    margin-bottom: 18px;
  }
  &--small {
    font-size: 14px;
    color: $celeste;
    font-family: $GothamRnd-Light;
  }
  &--white {
    color: white;
    font-size: 11px;
    background: $celeste3;
    border-radius: 14px 0 0 14px;
    padding: 7px 23px;
  }
}
.text {
  &--h5 {
    font-size: 17px;
    color: white;
    font-family: GothamRnd-Medium;
  }
  &--medium {
    font-size: 25px;
    color: white;
    font-family: GothamRnd-Medium;
  }
  &--title {
    font-size: 17px;
    color: white;
    font-family: GothamRnd-Medium;
    margin: 0;
  }
  &--light {
    font-size: 28px;
    color: $celeste;
    font-family: $GothamRnd-Light;
  }
  &--purple {
    font-size: 28px;
    color: $purple2;
    font-family: $GothamRnd-Medium;
  }
}
///////////////////////INDEX4///////////////////////

.text {
  &--small {
    text-align: end;
    & input {
      font-size: 12px;
    }
    & textarea {
      font-size: 12px;
    }
    @media screen and (max-width: 480px) {
      text-align: start;
    }
  }
}

///////////////////////INDEX5///////////////////////
.text {
  &--left {
    text-align: left;
  }
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
    @media screen and (max-width: 1200px) {
      text-align: start;
    }
  }
  &--basic {
    color: $celeste2;
    font-family: $GothamRnd-Light;
    font-size: 23px;
    margin: 0;
  }
  &--normal {
    color: $celeste2;
    font-family: $GothamRnd-Book;
    font-size: 23px;
    margin: 0;
  }
  &--normal2 {
    color: $celeste2;
    font-family: $GothamRnd-Book;
    font-size: 18px;
    margin-top: 7px;
    @media screen and (max-width: 360px) {
      font-size: 11px;
    }
    @media screen and (max-width: 1290px) {
      margin-top: 0px;
    }
  }
  &--bold {
    color: $purple2;
    font-family: $GothamRnd-Bold;
    font-size: 23px;
    margin: 0;
  }
  &--bold2 {
    color: $purple2;
    font-family: $GothamRnd-Bold;
    font-size: 18px;
    margin: 0;
    line-height: 7px;
    @media screen and (max-width: 375px) {
      font-size: 10px;
    }
  }
}
///////////////////////INDEX7///////////////////////
