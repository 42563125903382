.flex{
  display: flex;
  &--evenly {
    display: flex;
    justify-content: space-evenly;
  }
  &--column {
    display: flex;
    flex-direction: column;
  }
}
 

