.imagen {
  &--big {
    width: 43em;
    position: absolute;
    right: 100px;
    @media screen and (max-width: 1260px) {
      width: 43em;
      margin-right: -6em;
    }
    @media screen and (max-width: 480px) {
      width: 23em;
    }
    @media screen and (max-width: 350px) {
      width: 20em;
    }
  }
}
