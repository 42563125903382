@import "./variables";
@import "./title";
@import "./common";
@import "./footer";
@import "./typography";
@import "./button";
@import "./diplay";
@import "./image";
@import "./paragraph";
@import "./dropdown-menu";
@import "./navbar-contact";
@import "./navbar";
@import "./calendar";

body {
  overflow-x: hidden;
}

ul {
  font-size: 11px;
}

.nav-link:hover,
.nav-link:active {
  color: $celeste;
}

.phone img {
  margin: 0 5px;
}

.line-purple {
  background: $purple;
  height: 180px;
  width: 100%;
}
.section-galeria {
  display: flex;
  justify-content: flex-end;
  margin-top: -11.25em;
}
.imagen-fondo {
  height: 23em;
  position: relative;
  right: -150px;
  @media screen and (max-width: 1200px) {
    height: 19em;
    right: -250px;
  }
  @media screen and (max-width: 1024px) {
    height: 16em;
    right: -300px;
  }
  @media screen and (max-width: 768px) {
    height: 16em;
    right: 0;
  }
  @media screen and (max-width: 690px) {
    height: 15em;
    right: -50px;
  }
  @media screen and (max-width: 350px) {
    height: 10em;
  }
}
.galeria-imagenes {
  position: relative;
  top: 70px;
  right: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media screen and (max-width: 480px) {
    padding: 0 !important;
    margin-right: 5px !important;
  }
  @media screen and (max-width: 768px) {
    margin-right: 0 !important;
  }
  figure {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $celeste;
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;
    &.floating-bottom {
      background-color: $celeste3;
    }
  }
  .figure-whatApp {
    background: #00aa77;
  }

  img {
    max-height: 25px;
    @media screen and (max-width: 768px) {
      height: 15px;
    }
    &.image-email {
      max-height: 19px;
    }
  }
}
.section-imagenes {
  & .row {
    @media screen and (max-width: 480px) {
      margin-right: 0;
      margin-left: 0;
    }
  }
  & img {
    margin: 10px;
    width: 100%;
    @media screen and (max-width: 480px) {
      margin: 10px 0;
    }
  }
}
.page-info-top {
  flex: 1px;
  margin-top: -9em;
  margin-bottom: 3em;
  @media screen and (max-width: 768px) {
    margin-top: -8em;
    margin-bottom: 1em;
  }
  @media screen and (max-width: 350px) {
    margin-top: -12em;
  }
  & p {
    padding: 0 87px 0 0px;
    @media screen and (max-width: 480px) {
      padding: 0;
    }
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }
}

.select-event {
  margin-bottom: 20px;
  > div {
    position: relative;
    display: inline;

    i.fa-angle-down {
      position: absolute;
      right: 25px;
      top: -5px;
      color: $yellow;
      font-size: 30px;
      font-weight: bold;
      @media screen and (max-width: 480px) {
        right: 15px;
        top: -8px;
      }
    }
  }
  @media screen and (max-width: 480px) {
  }
}
.container-preparation div {
  margin: 15px 0px;
}

///////////////////////INDEX2///////////////////////

.teach {
  border: 2px solid $celeste;
  padding: 30px;
  border-radius: 15px;
  margin-bottom: 25px;
  & h5 {
    width: 50%;
  }
}
.benefits {
  border: 2px solid $celeste;
  padding: 30px;
  border-radius: 15px;
  @media screen and (max-width: 480px) {
    margin-bottom: 25px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 25px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 25px;
  }
  & h5 {
    width: 50%;
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }
}
.how-we-teach {
  height: 100%;
  border: 2px solid $celeste;
  padding: 30px;
  border-radius: 15px;
  .row {
    padding: 32px 50px 43px;
    @media screen and (max-width: 480px) {
      padding: 32px 10px 10px;
    }
  }
  & img {
    height: 90px;
  }
  & .col-sm-2 {
    padding-top: 10px;
  }
}
.container-left {
  &:first-of-type {
    margin-top: 40px;
  }
  display: flex;
  align-items: center;
  span {
    margin-right: -10px;
    margin-top: -5px;
    @media screen and (max-width: 823px) {
      padding: 9px 12px;
    }
  }
  @media screen and (max-width: 768px) {
    span {
      display: none;
    }
  }

  img {
    height: 25px;
  }
}
///////////////////////INDEX3///////////////////////

.slider__teachers {
  width: 100% !important;
  .slider__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100% !important;
  }
}
.our-team {
  &__header {
    width: 39%;
    margin-bottom: 60px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  &__teachers {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
  }
  &__teacher {
    display: flex;
    margin-bottom: 50px;
    width: 30%;
    @media screen and (max-width: 768px) {
      width: 50%;
    }
    @media screen and (max-width: 480px) {
      width: 48%;
    }
    @media screen and (max-width: 414px) {
      display: block;
    }
    &--avatar {
      height: 95px;
      @media screen and (max-width: 480px) {
        height: 70px;
      }
    }
    &--first-name {
      color: $celeste2;
      font-family: $GothamRnd-Book;
      font-size: 18px;
      margin-top: 10px;
      @media screen and (max-width: 360px) {
        font-size: 11px;
      }
    }
    &--last-name {
      color: $purple2;
      font-family: $GothamRnd-Bold;
      font-size: 18px;
      margin: 0;
      line-height: 7px;
      @media screen and (max-width: 375px) {
        font-size: 10px;
      }
    }
    &--role {
      margin-bottom: 20px;
    }
    &--info {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      justify-content: center;
      @media screen and (max-width: 480px) {
        margin-left: 10px;
      }
      p {
        margin-bottom: 10px;
      }
    }
    &--network-img {
      height: 23px;
      margin-top: 7px;
      margin-right: 5px;
    }
  }
}
///////////////////////INDEX4///////////////////////
iframe {
  margin-top: 10px;
  margin-bottom: -30px;
  @media screen and (max-width: 480px) {
    width: 480px;
    height: 300px;
  }
}
textarea {
  height: 150px !important;
  padding-top: 10px !important;
  @media screen and (max-width: 480px) {
    height: 200px !important;
  }
}
.contact-info {
  padding: 0% 20px 30px;
  margin-left: 29em !important;
  margin-top: -16em;
  margin-bottom: 3em !important;
  @media screen and (max-width: 1024px) {
    margin-left: 0 !important;
    margin-top: 11px !important;
    margin-bottom: 0 !important;
    padding-top: 19px !important;
    padding: 34px 5px !important;
  }
  @media screen and (max-width: 1024px) {
    margin-left: 0 !important;
    margin-top: 11px !important;
    margin-bottom: 0 !important;
    padding-top: 19px !important;
    padding: 34px 5px !important;
  }
  & img {
    width: 15px;
  }
  & span {
    margin-left: 5px;
  }
}
iframe {
  width: 100% !important;
}
///////////////////////INDEX5///////////////////////

.line {
  &--big {
    border-left: 1px solid $lead2;
    height: 8em;
    margin-top: -1em;
  }
  &--small {
    border-left: 1px solid #989898;
    height: 9px;
    margin: 2px 6px;
  }
  &--medium {
    border-left: 1px solid #989898;
    height: 18px;
    margin-top: 3px;
  }
}
///////////////////////INDEX7///////////////////////

.section {
  &--big {
    height: 600px;
    background: $purple;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
      height: 750px;
    }
    @media screen and (max-width: 480px) {
      height: 600px;
    }
    & h2 {
      color: white;
      font-family: $GothamRnd-Medium;
      font-size: 35px;
      @media screen and (max-width: 1024px) {
        font-size: 31px;
      }
      @media screen and (max-width: 320px) {
        font-size: 25px;
      }
    }
  }
}
.text-intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  padding-left: 7em;
  margin-top: -5em;
  @media screen and (max-width: 1024px) {
    width: 65%;
    margin-top: 26em;
    padding-left: 2em;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 3em;
  }
  @media screen and (max-width: 480px) {
    margin-top: 14em;
    margin-left: -1em;
  }
  & span {
    text-align: start;
  }
  & p {
    margin: 0;
  }
}
.text--small2 {
  font-size: 13px;
  color: $white;
  font-family: $GothamRnd-Book;
}
.section__items {
  display: flex;
  height: 600px;
}
.category img {
  width: 70px;
  height: 95px;
}
.category {
  margin-top: 7em;
  margin-left: 11em;
  @media screen and (max-width: 1024px) {
    margin-left: 7em;
  }
  @media screen and (max-width: 768px) {
    margin-left: 5em;
  }
  @media screen and (max-width: 375px) {
    margin-left: 3em;
  }
}

.we-help {
  background: $celeste !important;
}
.we-help div {
  padding: 9em 6em;
  @media screen and (max-width: 1024px) {
    padding: 4em 1em;
  }
}
.proposed-teacher {
  background-color: #f4f4f4;
  & .col-12 {
    padding: 60px 100px !important;
    @media screen and (max-width: 480px) {
      padding: 30px 20px !important;
    }
    @media screen and (max-width: 823px) {
      padding: 69px 36px !important;
    }
  }
}
.our-team__teacher2 {
  display: flex;
  margin-top: 35px;
  & img {
    height: 100px;
  }
}
.they-recommend-us {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  justify-content: center;
}
.meet-another-workshop {
  display: flex;
  & img {
    height: 190px;
    @media screen and (max-width: 768px) {
      height: 150px;
    }
    @media screen and (max-width: 375px) {
      height: 50%;
      width: 50%;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: -4em;
  }
  @media screen and (max-width: 375px) {
    flex-direction: column;
  }
}
.workshops-title {
  display: flex;
  flex-direction: column;
  margin-left: 35px;
  justify-content: center;
  @media screen and (max-width: 480px) {
    margin-left: 20px;
  }
}
.meet-others {
  padding-top: 100px;
  margin-bottom: 110px;
  @media screen and (max-width: 480px) {
    padding-top: 50px;
  }
  @media screen and (max-width: 823px) {
    margin-bottom: 80px;
    padding-top: 50px;
  }
}
.workshops-children {
  background-image: url(../assets/imagenes/bloque.png);
  background-position: center;
  background-size: cover;
  height: 330px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 480px) {
    height: 350px;
    align-items: center;
  }
  & span {
    font-size: 37px;
    font-family: $GothamRnd-Medium;
    color: white;
    @media screen and (max-width: 480px) {
      font-size: 26px;
    }
  }
  .col-xl-6 {
    padding-left: 140px;
    line-height: 1;
    margin-left: 3em;
    @media screen and (max-width: 823px) {
      margin-left: -5em;
      margin-top: 50px;
    }
  }
  .col-xl-4 {
    text-align: end;
    @media screen and (max-width: 731px) {
      text-align: start;
    }
    @media screen and (max-width: 823px) {
      text-align: start;
    }
  }
}

///////////////////////INDEX8///////////////////////
.ciclo-uni__nav {
  margin-bottom: 30px;
  & a {
    color: $white;
    padding: 10px 24px;
    border-radius: 6px;
    @media screen and (max-width: 480px) {
      padding: 8px 14px;
    }
    @media screen and (max-width: 768px) {
      padding: 7px 10px;
    }
  }
}
.course-selection {
  margin-bottom: 50px;
  @media screen and (max-width: 480px) {
    margin: 30px 0;
  }
  a {
    color: $lead;
    font-size: 13px;
    font-family: $GothamRnd-Medium;
    text-decoration: none !important;
    &.active {
      color: $purple;
    }
  }
}
.download-schedules {
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    margin: 10px 0;
  }
  & div {
    background: $celeste;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: -7px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & img {
    height: 25px;
  }
}
.schedules {
  color: white;
  font-size: 11px;
  background: $celeste;
  border-radius: 14px 0 0 14px;
  padding: 7px 15px;
  margin-left: 20px;
}
.registration__form {
  padding: 22px 14px;
  border-radius: 11px;
  margin-bottom: 25px;
  background-color: $celeste3;
  cursor: pointer;
}

.form-tab {
  margin-top: 30px;
  text-align: center;
  & input {
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    padding: 7px 15px;
    width: 85%;
    background-color: $celeste5;
    color: #ffffff;
    outline: none;
    font-family: $GothamRnd-Light;
  }
  & input::placeholder {
    color: #e28f72;
    font-size: 13px;
  }
}
.form-info {
  width: 80%;
  margin-left: 40px;
}
.description,
.modalities,
.requirements,
.more-information {
  padding: 20px 30px;
  border-radius: 10px;
  margin-bottom: 25px;
  background-color: $blue;
  cursor: pointer;
  h5 {
    margin: 0;
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0;
      color: $white;
      background-color: transparent;
      border: none;
      outline: none;
      i.fa-angle-down {
        color: $yellow;
        font-size: 50px;
        font-weight: bold;
      }
    }
  }
  hr:first-of-type {
    margin-top: 0;
    margin-bottom: 20px;
  }
  p {
    color: $white;
    font-size: 13px;
    font-family: $GothamRnd-Light;
  }
}
.requirements,
.more-information {
  background-color: $purple;
}
