
$purple: #5C0C7B; 
$celeste: #fd4409;
$purple2: #570B74;
$celeste2: #fd4409;
$celeste3: #fd4409; 
$celeste4: #fd4409;
$celeste5: #cb3605; 
$yellow: #FFDD08;
$blue: #fd4409; 
$blue2:#157FFB;

$white: #fff;
$lead: #9E9E9E; 
$lead2:#989898; 
$lead3: #8D8D8D;
$GothamRnd-Medium: 'GothamRnd-Medium';
$GothamRnd-Bold: 'GothamRnd-Bold';
$GothamRnd-Book: 'GothamRnd-Book';
$GothamRnd-Light: 'GothamRnd-Light';
